<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

/* 在CSS文件中 */

export default {


  created() {
    // this.$router.replace("home");
    // if (this.$router.path !== "/home") {
    //   if (sessionStorage.getItem("userCode")) {
    //     this.$router.replace("home");
    //   } else {
    //     this.$router.replace("home");
    //   }
    // }
  },
  mounted() {
  
  },

  methods: {
   
  }
};
</script>
 
<style>
* {
  margin: 0;
  padding: 0;
}
body {
  width: 100% !important;
  margin: 0 auto !important;
}
#app {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  margin-top: 60px;
  background: #f5f5f5;
  font-weight: 400;
  font-family: Noto Sans SC;
  font-style: normal;
}

.el-input__inner {
}
.el-input--prefix .el-input__inner {
  border: none;
}

.el-input--prefix .el-input__inner {
  border: none;
}

.el-table .el-table__cell {
  padding: 0 !important;
}

.el-textarea__inner {
  height: 35px !important;
  min-height: 35px !important;
  border-radius: 0 !important;
}

a {
  text-decoration: none; /* 去掉下划线 */
  color: inherit; /* 继承父元素颜色，状态不变 */
}
/* a:hover {
  color: #333; 
} */
a:active,
a:focus {
  outline: none; /* 去掉点击时的虚线框 */
  color: #333; /* 点击时的颜色 */
}
p {
  margin: 0;
}
div {
  word-break: break-all;
}
.template {
  width: 100%;
}

ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
li {
  list-style-type: none;
  text-align: center;
  cursor: pointer;
}

/* 当屏幕宽度小于1200px时应用的样式 */
@media (max-width: 1200px) {
  .newHead {
    width: 1200px;
  }

  .template {
    width: 1200px;
    margin: 0 auto;
  }
}

/* 当屏幕宽度大于1200px时应用的样式 */
@media (min-width: 1200px) {
  .newHead {
    width: 100%;
    padding: 0;
  }
  .template {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
